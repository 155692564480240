// import HeroImg from "../../../assets/images/banner.svg";
import HeroImg from "../../../assets/images/transparent_hero.gif";
import styles from "./Hero.module.css";
import Button from "../../../components/Button";
import BckRing from "../../../assets/images/herounderlay.png";
import ButtonArr from "../../../components/Button/ButtonArr";
import CircleIcon from "@mui/icons-material/Circle";
// import Info from "../../../assets/images/hero.gif";

const Hero = () => {
  return (
    <div className={`${styles.herocontainer}`}>
      <div className={`${styles.container}`}>
        <div className={`${styles.contmargin} flex space-between`}>
          <div className={`${styles.heroflexleft}`}>
            <div
              className={`${styles.herofirst} flex gap-24 align-item-center`}
            >
              <CircleIcon className="light-blue" />
              <p>We are building the future of school management</p>
            </div>
            <h1 className={`${styles.lgscreen}`}>Transform how you manage all your school activities.</h1>
            <h1 className={`${styles.smscreen}`}>Streamline Your School Management with Shelf21.</h1>
            <h6>
              Empowering Schools, Engaging Students, and Connecting Parents, all
              in one platform.
            </h6>
            <div className="flex gap-16 flex-wrap">
              <ButtonArr title="Get Started" />
              <Button title="Book a Demo" />
            </div>
          </div>
          <img src={HeroImg} alt="" className={`${styles.heroimage}`} max-width="100%" />
        </div>
        <img src={BckRing} alt="" className={`${styles.ringone}`} />
        <img src={BckRing} alt="" className={`${styles.ringtwo}`} />
      </div>
    </div>
  );
};

export default Hero;
