import styles from "./Pricing.module.css";
import { useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabContext, TabPanel } from "@mui/lab";
import PriceCard from "./components/PriceCard";
import { Grid } from "@mui/material";

const Pricing = () => {
  const [value, setValue] = useState("1");

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={`${styles.pricingcontainer}`}>
      <div className={`${styles.container}`}>
        <div className={`${styles.contmargin}`}>
          <div className={`${styles.pricingheader}`}>
            <h3>Shelf21 Pricing Options</h3>
            <p>
              Record and report on safeguarding information including background
              checks, and safer recruitment trainingRecord and report on
              safeguarding.
            </p>
          </div>
          <div className={`${styles.shelfpricing}`}>
            <TabContext value={value}>
              <Box
                sx={{
                  maxWidth: "787px",
                  margin: "auto",
                  bgcolor: "#E6F0FF",
                  borderRadius: "8px",
                  padding: "8px",
                  marginBottom: "30px",
                  color: "#0065FF"
                }}
              >
                <Tabs
                  onChange={handleChange}
                  TabIndicatorProps={{ style: { display: "none" } }}
                  value={value}
                  centered
                  sx={{
                    "& .MuiTab-root.Mui-selected": {
                      background: "#0065FF",
                      color: "#FFF",
                      borderRadius: "8px"
                    },
                    "& button": {
                      color: "#0065FF",
                      fontSize: "20px",
                      fontWeight: "700",
                      paddingInline: "78px"
                    },
                    "& button:hover": {
                      backgroundColor: "#0065FF",
                      color: "#FFF",
                      transition: "1s",
                      borderRadius: "8px" },
                    "& button:active": {
                      backgroundColor: "#0065FF",
                      color: "#FFF",
                      transition: "1s",
                      borderRadius: "8px"
                    },
                    "& button:focus": {
                      backgroundColor: "#0065FF",
                      color: "#FFF",
                      transition: "1s",
                      borderRadius: "8px"
                    },
                    "& button:not(:last-child)": {
                      marginRight: "8px"
                    }
                  }}
                >
                  <Tab label="Monthly" value="1" />
                  <Tab label="Quaterly" value="2" />
                  <Tab label="Yearly" value="3" />
                </Tabs>
              </Box>
              <TabPanel value="1">
                <Grid container spacing={2}>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <PriceCard
                      amount="N10,000"
                      payType="Month"
                      planType="Basic Plan"
                      planDescription="Record and report on safeguarding information including background checks."
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <PriceCard
                      amount="N10,000"
                      payType="Month"
                      planType="Basic Plan"
                      planDescription="Record and report on safeguarding information including background checks."
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <PriceCard
                      amount="N10,000"
                      payType="Month"
                      planType="Basic Plan"
                      planDescription="Record and report on safeguarding information including background checks."
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="2">
                <Grid container spacing={2}>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <PriceCard
                      amount="N26,000"
                      payType="Quater"
                      planType="Basic Plan"
                      planDescription="Record and report on safeguarding information including background checks."
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <PriceCard
                      amount="N36,000"
                      payType="Quater"
                      planType="Basic Plan"
                      planDescription="Record and report on safeguarding information including background checks."
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <PriceCard
                      amount="N40,000"
                      payType="Quater"
                      planType="Basic Plan"
                      planDescription="Record and report on safeguarding information including background checks."
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="3">
                <Grid container spacing={2}>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <PriceCard
                      amount="N109,000"
                      payType="Year"
                      planType="Basic Plan"
                      planDescription="Record and report on safeguarding information including background checks."
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <PriceCard
                      amount="N130,000"
                      payType="Year"
                      planType="Basic Plan"
                      planDescription="Record and report on safeguarding information including background checks."
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <PriceCard
                      amount="N150,000"
                      payType="Year"
                      planType="Basic Plan"
                      planDescription="Record and report on safeguarding information including background checks."
                    />
                  </Grid>
                </Grid>
              </TabPanel>
            </TabContext>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
