import styles from "./Button.module.css";
import PropTypes from "prop-types";

const ButtonSm = ({ title }) => {
  return (
    <div className={`${styles.btnsm}`}>
      <p>{title}</p>
    </div>
  );
};
ButtonSm.PropTypes = {
  title: PropTypes.string
};

export default ButtonSm;
