import styles from "./ApiProcurement.module.css";
import PropTypes from "prop-types";
import NorthEastIcon from "@mui/icons-material/NorthEast";

const ApiProcurement = ({ image, apiTitle, description }) => {
  return (
    <div className={`${styles.procurementinfo}`}>
      <img src={image} alt="" />
      <h5>{apiTitle}</h5>
      <p>{description}</p>
      <a href="">Learn More</a>
      <NorthEastIcon />
      <div className="flex align-item-center gap-8">
        <div className="little-margin-top light-blue">
        </div>
      </div>
    </div>
  );
};
ApiProcurement.PropTypes = {
  image: PropTypes.any,
  apiTitle: PropTypes.string,
  description: PropTypes.string
};

export default ApiProcurement;
