import styles from "./FeatureComponent.module.css";
import PropTypes from "prop-types";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FeatureImg from "../../../assets/images/featureimg.png";

const AllFeatures = ({ title, featuretitle, featuredesc, backgroundColor, fontColor }) => {
  const contbcgcolor = {
    background: backgroundColor
  };
  const featurecolor = {
    color: fontColor
  };

  return (
    <div className={`${styles.forfeatures}`} style={contbcgcolor}>
      <div className={`${styles.featureleft}`}>
        <div className={`${styles.featureleftheader}`}>
          <h6 style={featurecolor}>{title}</h6>
          <h5>{featuretitle}</h5>
          <p className={`${styles.featuredesc}`}>{featuredesc}</p>
        </div>
        <div className="flex gap-16">
          <div>
            <CheckCircleOutlineIcon />
          </div>
          <p>Get daily student Attendance</p>
        </div>
        <div className="flex gap-16 margin-top-25">
          <div>
            <CheckCircleOutlineIcon />
          </div>
          <p>Send Attendance report to parents</p>
        </div>
        <div className="flex gap-16 margin-top-25">
          <div>
            <CheckCircleOutlineIcon />
          </div>
          <p>Overview of overall attendance record of students</p>
        </div>
        <div className="flex gap-16 margin-top-25">
          <div>
            <CheckCircleOutlineIcon />
          </div>
          <p>Something along the lines of attendance still.</p>
        </div>
      </div>
      <div className={`${styles.featureright}`}>
        <img src={FeatureImg} alt="feature" />
      </div>
    </div>
  );
};
AllFeatures.PropTypes = {
  backgroundColor: PropTypes.string,
  fontColor: PropTypes.string,
  title: PropTypes.string
};

export default AllFeatures;
