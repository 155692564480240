import Header from "../../components/Header/index";
import Footer from "../../components/Footer/index";
import Policies from "./component/Policies";
import styles from "./Policy.module.css";
// import PropTypes from "prop-types";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import Typography from "@mui/material/Typography";
// import Box from "@mui/material/Box";
// import { useState } from "react";
import { Grid } from "@mui/material";
// import { Link } from "react-router-dom";
// import { HashLink } from "react-router-hash-link";
import CircleIcon from "@mui/icons-material/Circle";

const index = () => {
  // const [value, setValue] = useState(0);

  // const TabPanel = (props) => {
  //   const { children, value, index, ...other } = props;

  //   return (
  //     <div
  //       role="tabpanel"
  //       hidden={value !== index}
  //       id={`vertical-tabpanel-${index}`}
  //       aria-labelledby={`vertical-tab-${index}`}
  //       {...other}
  //     >
  //       {value === index && (
  //         <Box sx={{ p: 3 }}>
  //           <Typography>{children}</Typography>
  //         </Box>
  //       )}
  //     </div>
  //   );
  // };

  // TabPanel.propTypes = {
  //   children: PropTypes.node,
  //   index: PropTypes.number.isRequired,
  //   value: PropTypes.number.isRequired
  // };

  // const a11yProps = (index) => {
  //   return {
  //     id: `vertical-tab-${index}`,
  //     "aria-controls": `vertical-tabpanel-${index}`
  //   };
  // };

  // const handleChange = (_event, newValue) => {
  //   setValue(newValue);
  // };

  return (
    <div>
      <Header />
      <div className={`${styles.containerbcg}`}>
        <div className={`${styles.container}`}>
          <div className={`${styles.contmargin}`}>
            <Policies
              policyTitle="Privacy Policy"
              policySubTitle="Effective from May 5th, 2024."
            />
            {/* <div className={`${styles.policymaincont}`}>
            <Box
              sx={{
                // flexGrow: 1,
                bgcolor: "background.paper",
                textAlign: "left"
                // display: "flex"
                // height: 224
              }}
            >
              <Grid container columnGap={0}>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{
                      borderRight: 1,
                      borderColor: "divider",
                      "& MuiTabs-flexContainer": {
                        alignItems: "flex-start"
                      }
                    }}
                  >
                    <Tab
                      sx={{
                        textAlign: "left",
                        alignSelf: "flex-start",
                        "&.Mui-selected": {
                          color: "#0065FF",
                          transition: "0.5s",
                          fontSize: "20px",
                          fontWeight: 600,
                          lineHeight: "24px"
                        }
                      }}
                      label="1.  Who are we?"
                      {...a11yProps(0)}
                    />
                    <Tab label="2.  Definitions" {...a11yProps(1)} />
                    <Tab
                      label="3.  How do we collect personl Data?"
                      {...a11yProps(2)}
                    />
                    <Tab
                      label="4   Children and Sensitive Data"
                      {...a11yProps(3)}
                    />
                    <Tab
                      label="5.  Personal Data that we process, our purpose for process etc"
                      {...a11yProps(4)}
                    />
                    <Tab
                      label="6.  Purpose: Demo Registration - Creation of free account"
                      {...a11yProps(5)}
                    />
                    <Tab label="7.  Legal Grounds" {...a11yProps(6)} />
                    <Tab label="8.  Legal Grounds" {...a11yProps(7)} />
                  </Tabs>
                </Grid>
                <Grid
                  item
                  className={`${styles.policyrightitem}`}
                  lg={9}
                  md={9}
                  sm={9}
                  xs={9}
                >
                  <TabPanel sx={{ color: "black" }} value={value} index={0}>
                    <h5>who are we?</h5>
                    <p className={`${styles.policycontent}`}>
                      The website the app app.chmeetings.com are owned and
                      administrated by Jios Apps Inc, an US software company
                      with billing address at 98 Cuttermill Road Suite 466 S,
                      Great Neck, New York (NY) 11021. As used in this Privacy
                      Policy, “ChMeetings”, “us”, “we” and “Jios Apps Inc.”
                      refers to Jios Apps Inc. ChMeetings cares about helping
                      churches and supporting their services through technology.
                      We hope to improve the relationship between churches and
                      members and are continuously working towards finding
                      innovative tools that help servants perform their duties
                      more effectively.
                    </p>
                    <h6 className={`${styles.textsubheader}`}>Definitions</h6>
                    <div className="flex gap-8 margin-top-20">
                      <div className={`${styles.circleforparagraph}`}>
                        <CircleIcon sx={{ height: "6px", color: "black" }} />
                      </div>
                      <p className={`${styles.policycontent}`}>
                        Personal data or personal information - means any
                        information regarding an identified or identifiable
                        natural person.
                      </p>
                    </div>
                    <div className="flex gap-8 margin-top-20">
                      <div className={`${styles.circleforparagraph}`}>
                        <CircleIcon sx={{ height: "6px", color: "black" }} />
                      </div>
                      <p className={`${styles.policycontent}`}>
                        Controller - means the natural or legal person that
                        determines the purposes and means of the processing of
                        personal data processing. Regarding the personal data
                        processing activities performed through the website and
                        app, the Controller is Jios Apps Inc, hereby referred to
                        as “ChMeetings”, “us” or “we”.
                      </p>
                    </div>
                    <div className="flex gap-8 margin-top-20">
                      <div className={`${styles.circleforparagraph}`}>
                        <CircleIcon sx={{ height: "6px", color: "black" }} />
                      </div>
                      <p className={`${styles.policycontent}`}>
                        Data subject - means the identified or identifiable
                        natural person the personal data refers to, hereby
                        referred to “you” or “your”.
                      </p>
                    </div>
                    <div className="flex gap-8 margin-top-20">
                      <div className={`${styles.circleforparagraph}`}>
                        <CircleIcon sx={{ height: "6px", color: "black" }} />
                      </div>
                      <p className={`${styles.policycontent}`}>
                        Processing - means any operation or set of operations
                        which is performed on personal data or on sets of
                        personal data, whether or not by automated means, such
                        as collection, storage, adaptation, consultation, use,
                        disclose or deletion.
                      </p>
                    </div>
                    <div className="flex gap-8 margin-top-20">
                      <div className={`${styles.circleforparagraph}`}>
                        <CircleIcon sx={{ height: "6px", color: "black" }} />
                      </div>
                      <p className={`${styles.policycontent}`}>
                        Personal data or personal information - means any
                        information regarding an identified or identifiable
                        natural person.
                      </p>
                    </div>
                    <div className="flex gap-8 margin-top-20">
                      <div className={`${styles.circleforparagraph}`}>
                        <CircleIcon sx={{ height: "6px", color: "black" }} />
                      </div>
                      <p className={`${styles.policycontent}`}>
                        Consent of the Data subject - means any freely given,
                        specific, informed and unambiguous indication of the
                        user's wishes by which he or she, by a statement or by a
                        clear affirmative action, signifies agreement to the
                        processing of personal data relating to him or her;
                      </p>
                    </div>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    Item Two
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    Item Three
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    Item Four
                  </TabPanel>
                  <TabPanel value={value} index={4}>
                    Item Five
                  </TabPanel>
                  <TabPanel value={value} index={5}>
                    Item Six
                  </TabPanel>
                  <TabPanel value={value} index={6}>
                    Item Seven
                  </TabPanel>
                </Grid>
              </Grid>
            </Box>
          </div> */}
            {/* CONTENT */}
            <div className={`${styles.policymaincont}`}>
              <Grid container gap="24px">
                <Grid className={`${styles.itemleft}`} item lg={3} md={3} sm={3} xs={3}>
                  <div className="flex gap-8">
                    <p>1.</p>
                    <a href="#whowe">Who are we?</a>
                  </div>
                  <div className="flex gap-8">
                    <p>2.</p>
                    <a href="#definition">Definition</a>
                  </div>
                  <div className="flex gap-8">
                    <p>3.</p>
                    <a href="#personaldata">How do we collect personal data?</a>
                  </div>
                  <div className="flex gap-8">
                    <p>4.</p>
                    <a href="#sensitive">Children and Sensitive Data</a>
                  </div>
                  <div className="flex gap-8">
                    <p>5.</p>
                    <a href="#personal">Personal Data that we process, our purpose for process etc</a>
                  </div>
                  <div className="flex gap-8">
                    <p>6.</p>
                    <a href="#purpose">Purpose: Demo Registration - Creation of free account</a>
                  </div>
                  <div className="flex gap-8">
                    <p>7.</p>
                    <a href="#legal">Legal Grounds</a>
                  </div>
                  <div className="flex gap-8">
                    <p>8.</p>
                    <a href="">Retention Period</a>
                  </div>
                </Grid>
                <Grid item className={`${styles.itemright}`} lg={8} md={8} sm={8} xs={8}>
                  <h5 id="whowe">Who are we</h5>
                  <p className={`${styles.policycontent}`}>
                      The website the app app.chmeetings.com are owned and
                      administrated by Jios Apps Inc, an US software company
                      with billing address at 98 Cuttermill Road Suite 466 S,
                      Great Neck, New York (NY) 11021. As used in this Privacy
                      Policy, “ChMeetings”, “us”, “we” and “Jios Apps Inc.”
                      refers to Jios Apps Inc. ChMeetings cares about helping
                      churches and supporting their services through technology.
                      We hope to improve the relationship between churches and
                      members and are continuously working towards finding
                      innovative tools that help servants perform their duties
                      more effectively.
                  </p>
                  {/* DEFINITION SECTION */}
                  <h6 id="definitions" className={`${styles.textsubheader}`}>Definitions</h6>
                  <div className="flex gap-8 margin-top-20">
                    <div className={`${styles.circleforparagraph}`}>
                      <CircleIcon sx={{ height: "6px", color: "black" }} />
                    </div>
                    <p className={`${styles.policycontent}`}>
                        Personal data or personal information - means any
                        information regarding an identified or identifiable
                        natural person.
                    </p>
                  </div>
                  <div className="flex gap-8 margin-top-20">
                    <div className={`${styles.circleforparagraph}`}>
                      <CircleIcon sx={{ height: "6px", color: "black" }} />
                    </div>
                    <p className={`${styles.policycontent}`}>
                        Controller - means the natural or legal person that
                        determines the purposes and means of the processing of
                        personal data processing. Regarding the personal data
                        processing activities performed through the website and
                        app, the Controller is Jios Apps Inc, hereby referred to
                        as “ChMeetings”, “us” or “we”.
                    </p>
                  </div>
                  <div className="flex gap-8 margin-top-20">
                    <div className={`${styles.circleforparagraph}`}>
                      <CircleIcon sx={{ height: "6px", color: "black" }} />
                    </div>
                    <p className={`${styles.policycontent}`}>
                        Data subject - means the identified or identifiable
                        natural person the personal data refers to, hereby
                        referred to “you” or “your”.
                    </p>
                  </div>
                  <div className="flex gap-8 margin-top-20">
                    <div className={`${styles.circleforparagraph}`}>
                      <CircleIcon sx={{ height: "6px", color: "black" }} />
                    </div>
                    <p className={`${styles.policycontent}`}>
                        Processing - means any operation or set of operations
                        which is performed on personal data or on sets of
                        personal data, whether or not by automated means, such
                        as collection, storage, adaptation, consultation, use,
                        disclose or deletion.
                    </p>
                  </div>
                  <div className="flex gap-8 margin-top-20">
                    <div className={`${styles.circleforparagraph}`}>
                      <CircleIcon sx={{ height: "6px", color: "black" }} />
                    </div>
                    <p className={`${styles.policycontent}`}>
                        Personal data or personal information - means any
                        information regarding an identified or identifiable
                        natural person.
                    </p>
                  </div>
                  <div className="flex gap-8 margin-top-20">
                    <div className={`${styles.circleforparagraph}`}>
                      <CircleIcon sx={{ height: "6px", color: "black" }} />
                    </div>
                    <p id="personaldata" className={`${styles.policycontent}`}>
                        Consent of the Data subject - means any freely given,
                        specific, informed and unambiguous indication of the
                        user's wishes by which he or she, by a statement or by a
                        clear affirmative action, signifies agreement to the
                        processing of personal data relating to him or her;
                    </p>
                  </div>
                  {/* HOWN WE COLLECT PERSONAL DATA */}
                  <div>
                    <h6 className={`${styles.textsubheader}`}>How do we collect personal data?</h6>
                  </div>
                  <div className="flex gap-8 margin-top-20">
                    <div className={`${styles.circleforparagraph}`}>
                      <CircleIcon sx={{ height: "6px", color: "black" }} />
                    </div>
                    <p className={`${styles.policycontent}`}>
                    As a rule, the Personal Data we process is collected directly from you. However,
                     there may be situations where data is collected indirectly from social media,
                      from the website of the company you represent, from your employer as a contact
                       person, from a third party who recommended you or from various public platforms
                        (for example ad platforms).
                    </p>
                  </div>
                  <div className="flex gap-8 margin-top-20">
                    <div className={`${styles.circleforparagraph}`}>
                      <CircleIcon sx={{ height: "6px", color: "black" }} />
                    </div>
                    <p id="sensitive" className={`${styles.policycontent}`}>
                    When we, as a Controller, do not receive personal data directly from you as a
                     user of the website and/or app, we will inform you accordingly on the processing
                      activities within the legal term.
                    </p>
                  </div>
                  {/* SENSITIVE DATA */}
                  <div>
                    <h6 className={`${styles.textsubheader}`}>Children and Sensitive Data</h6>
                  </div>
                  <div className="flex gap-8 margin-top-20">
                    <div className={`${styles.circleforparagraph}`}>
                      <CircleIcon sx={{ height: "6px", color: "black" }} />
                    </div>
                    <p className={`${styles.policycontent}`}>
                    Our Website and App are not intended for individuals below the age of 16. We do not
                     intentionally collect Personal Information from children below the age of 16.
                    </p>
                  </div>
                  <div className="flex gap-8 margin-top-20">
                    <div className={`${styles.circleforparagraph}`}>
                      <CircleIcon sx={{ height: "6px", color: "black" }} />
                    </div>
                    <p className={`${styles.policycontent}`}>
                    If you are the parent or legal guardian of a child below the age of 16 and you believe
                     your child has provided us with personal information, please contact us to request the
                      deletion of these personal data. If we confirm that we have collected this kind of personal
                       information, we will delete that information as soon as possible.
                    </p>
                  </div>
                  <div className="flex gap-8 margin-top-20">
                    <div className={`${styles.circleforparagraph}`}>
                      <CircleIcon sx={{ height: "6px", color: "black" }} />
                    </div>
                    <p id="personal" className={`${styles.policycontent}`}>
                    We do not collect nor is our intention to collect personal data revealing racial or ethnic origin,
                     political opinions, religious or philosophical beliefs, or trade union membership, genetic data,
                      biometric data for the purpose of uniquely identifying a natural person, data concerning health
                       or data concerning a natural person's sex life or sexual orientation or personal data relating
                        to criminal convictions and offences or related security measures, excepting the situations expressively
                         regulated by the applicable law.
                    </p>
                  </div>
                  {/* PERSONAL DATA */}
                  <div>
                    <h6 className={`${styles.textsubheader}`}>Personal Data that we process, our purpose for process etc</h6>
                  </div>
                  <div className="flex gap-8 margin-top-20">
                    <div className={`${styles.circleforparagraph}`}>
                      <CircleIcon sx={{ height: "6px", color: "black" }} />
                    </div>
                    <p className={`${styles.policycontent}`}>
                    Below you will find information about the purposes for which we Process your Personal Data, the categories of
                     Personal Data we collect for those purposes, the legal grounds on which we carry out the Processing activities
                      and the periods of time we store the Personal Data, in relation to the purposes of the Processing.
                    </p>
                  </div>
                  <div className="flex gap-8 margin-top-20">
                    <div className={`${styles.circleforparagraph}`}>
                      <CircleIcon sx={{ height: "6px", color: "black" }} />
                    </div>
                    <p id="purpose" className={`${styles.policycontent}`}>
                    Where the lawful basis for the Processing is the Data Subject's Consent, you may withdraw your consent at any
                     time without constraint and without affecting the lawfulness of the Processing prior to its withdrawal.
                    </p>
                  </div>
                  {/* PURPOSE */}
                  <div>
                    <h6 className={`${styles.textsubheader}`}>Purpose: Demo Registration - Creation of free account</h6>
                  </div>
                  <div className="flex gap-8 margin-top-20">
                    <div className={`${styles.circleforparagraph}`}>
                      <CircleIcon sx={{ height: "6px", color: "black" }} />
                    </div>
                    <p className={`${styles.policycontent}`}>
                    Creation of a free full functional account allows you to add your own people and data and will qualify you as data
                     controller for the processing activities that you carry out. We have no control, and we are not responsible for the
                      processing activities that you perform over the personal data you process within the account.
                    </p>
                  </div>
                  <div className="flex gap-8 margin-top-20">
                    <div className={`${styles.circleforparagraph}`}>
                      <CircleIcon sx={{ height: "6px", color: "black" }} />
                    </div>
                    <p className={`${styles.policycontent}`}>
                    Personal data categories: First name, last name, email, language. It is optional to provide us with your photo, middle name,
                     date of birth, gender and mobile phone number.
                    </p>
                  </div>
                  <div className="flex gap-8 margin-top-20">
                    <div className={`${styles.circleforparagraph}`}>
                      <CircleIcon sx={{ height: "6px", color: "black" }} />
                    </div>
                    <p className={`${styles.policycontent}`}>
                    Depending on your choice, we will also collect and process the name of the church you represent in order to know who is our legal
                     entity client for which the account is created. We have no intention of creating or using data in a way that could be considered
                      as a special category of personal data, or to derive sensitive conclusions from personal data.
                    </p>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default index;
