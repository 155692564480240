import styles from "./Faq.module.css";
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const index = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (_event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.contmargin}`}>
        <div className={`${styles.faqheader}`}>
          <h3>Frequently Asked Questions</h3>
        </div>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          sx={{ boxShadow: "none", paddingBlock: "20px", "&.Mui-expanded": { backgroundColor: "#E6F0FF", borderRadius: "20px", paddingBlock: "16px", transition: "0.6s", fontWeight: "500" } }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{ alignItems: "center" }}
          >
            <div className={`${styles.questionmark}`}>
              <HelpOutlineIcon />
            </div>
            <h4 className={`${styles.question} margin-top-10`}>
              What's your pricing structure like?
            </h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className={`${styles.answers}`}>
              The schools need only to register and download the application to
              be able to use the platform with ease. They can share with all
              their staff members too.
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          sx={{ boxShadow: "none", paddingBlock: "25px", "&.Mui-expanded": { backgroundColor: "#E6F0FF", borderRadius: "20px", paddingBlock: "16px", transition: "0.6s" } }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <div className={`${styles.questionmark}`}>
              <HelpOutlineIcon />
            </div>
            <h4 className={`${styles.question} margin-top-10 margin-inline-24`}>
              How are schools going to get access
            </h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className={`${styles.answers}`}>
              The schools need only to register and download the application to
              be able to use the platform with ease. They can share with all
              their staff members too.
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          sx={{ boxShadow: "none", paddingBlock: "25px", "&.Mui-expanded": { backgroundColor: "#E6F0FF", borderRadius: "20px", paddingBlock: "16px", transition: "0.6s" } }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <div className={`${styles.questionmark}`}>
              <HelpOutlineIcon />
            </div>
            <h4 className={`${styles.question} margin-top-10 margin-inline-24`}>
              What is the project management like here?
            </h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className={`${styles.answers}`}>
              The schools need only to register and download the application to
              be able to use the platform with ease. They can share with all
              their staff members too.
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
          sx={{ boxShadow: "none", paddingBlock: "25px", "&.Mui-expanded": { backgroundColor: "#E6F0FF", borderRadius: "20px", paddingBlock: "16px", transition: "0.6s" } }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <div className={`${styles.questionmark}`}>
              <HelpOutlineIcon />
            </div>
            <h4 className={`${styles.question} margin-top-10 margin-inline-24`}>
              Can students, parents and teachers interact in-app?
            </h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className={`${styles.answers}`}>
              The schools need only to register and download the application to
              be able to use the platform with ease. They can share with all
              their staff members too.
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
          sx={{ boxShadow: "none", paddingBlock: "25px", "&.Mui-expanded": { backgroundColor: "#E6F0FF", borderRadius: "20px", paddingBlock: "16px", transition: "0.6s" } }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5bh-content"
            id="panel5bh-header"
          >
            <div className={`${styles.questionmark}`}>
              <HelpOutlineIcon />
            </div>
            <h4 className={`${styles.question} margin-top-10 margin-inline-24`}>
              Can resources be uploaded and downloaded?
            </h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className={`${styles.answers}`}>
              The schools need only to register and download the application to
              be able to use the platform with ease. They can share with all
              their staff members too.
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
          sx={{ boxShadow: "none", paddingBlock: "25px", "&.Mui-expanded": { backgroundColor: "#E6F0FF", borderRadius: "20px", paddingBlock: "16px", transition: "0.6s" } }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6bh-content"
            id="panel6bh-header"
          >
            <div className={`${styles.questionmark}`}>
              <HelpOutlineIcon />
            </div>
            <h4 className={`${styles.question} margin-top-10 margin-inline-24`}>
              Can resources be uploaded and downloaded?
            </h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className={`${styles.answers}`}>
              The schools need only to register and download the application to
              be able to use the platform with ease. They can share with all
              their staff members too.
            </p>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default index;
