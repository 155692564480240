import styles from "./AboutShelf.module.css";
// import TrialGif from "../../../assets/images/hero.gif";
import ShelfOverview from "../../../assets/images/shelfoverview.png";
import Feature from "../../../components/Feature/Feature";
import FeatureReverse from "../../../components/Feature/FeatureReserve";
import FeatureIcon from "../../../assets/images/featureicon.svg";
import FeatureIconB from "../../../assets/images/featureiconblue.svg";
import FeatureIconG from "../../../assets/images/featureicongreen.svg";
import Button from "../../../components/Button";
import ButtonArr from "../../../components/Button/ButtonArr";
import ShelfStudent from "../../../assets/images/slider1.jpg";
import BrandingVideo from "../../../assets/videos/branding.mp4";
import StudentApplication from "../../../assets/videos/studentapplication.mp4";
import Datamgt from "../../../assets/videos/datamgt.mp4";
import ReactPlayer from "react-player";
// import VideoImg from "../../../assets/images/video.svg";

const index = () => {
  return (
    <>
      <div className={`${styles.background}`}>
        <div className={`${styles.container}`}>
          <div className={`${styles.contmargin}`}>
            <div className={`${styles.aboutheader}`}>
              <p>One Stop Shop for all your school management.</p>
              <h2>Take the best care of staff and students.</h2>
            </div>
            <img
              src={ShelfOverview}
              alt="Shelf Overview"
              width="100%"
              className={`${styles.shelfimg}`}
            />
          </div>
        </div>
      </div>
      <div className={`${styles.featurescont}`}>
        <div className={`${styles.features}`}>
          <h3>Features on Shelf21 </h3>
          <p>
            Record and report on safeguarding information including background
            checks, and safer recruitment
            trainingRecord and report on safeguarding information including
            background checks, and safer recruitment training
          </p>
        </div>
      </div>
      <div className={`${styles.featureitems}`}>
        <Feature type="video" asset={Datamgt} featureicon={FeatureIcon} featureTitle="Effective Data Management across board"
          featureDescription="It allows for easy management of school records and caters to the diverse needs of administrators, teachers, staff, parents, and students. The app provides schools with actionable insights to improve operational efficiency and student outcomes."
        />
        <FeatureReverse type="video" asset={StudentApplication} featureicon={FeatureIconB} featureTitle="Student Application and Enrollment Management"
          featureDescription="This feature streamlines the entire process from application submission to final enrollment. It includes online application forms, document uploads, application status tracking, and automated notifications for successful enrollments.
"
        />
        <Feature type="video" asset={BrandingVideo} featureicon={FeatureIconG} featureTitle="Effortless branding and customization of your school application"
          featureDescription="Schools can customize their dashboard interface with logos, color schemes, and branding elements to reflect their unique identity and brand image. This feature enhances your school's brand recognition among students, parents, and alumni.
"
        />
      </div>
      <div className={`${styles.bckgroundblack}`}>
        <div className={`${styles.container}`}>
          <div className={`${styles.contmargin}`}>
            <div className={`${styles.staffstdn}`}>
              <h2>Take care of your staff and students</h2>
              <p>Record and report on safeguarding information including background checks, and safer recruitment
                trainingRecord and report on safeguarding information including background checks, and safer recruitment training
              </p>
            </div>
            <div className="flex gap-16 flex-wrap margin-top-32 margin-bottom-200">
              <ButtonArr title="Get Started" />
              <Button title="Book a Demo" />
            </div>
          </div>
          <div>
            <img src={ShelfStudent} alt="Shelf Student" width="100%" />
          </div>
        </div>
      </div>
      <div className={`${styles.container}`}>
        <div className={`${styles.videocont}`}>
          <h3>Discover more about Shelf21 with a short preview video</h3>
          <div className={`${styles.forvideo}`}>
            <ReactPlayer width="100%" height="100%" style={{ margin: "auto", borderRadius: "19.89px" }} url="https://youtu.be/G9H2aliqkq8?si=v8dYb34uuV5oGyh4" />
          </div>
        </div>
      </div>
    </>
  );
};

export default index;
