import Header from "../../components/Header/index";
import Footer from "../../components/Footer/index";
import styles from "./FeaturePage.module.css";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState } from "react";
// import PriceCard from "../../components/Pricing/components/PriceCard";
import AllFeatures from "./components/AllFeatures";

const index = () => {
  const [value, setValue] = useState("1");

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Header />
      <div className={`${styles.containerbcg}`}>
        <div className={`${styles.container}`}>
          <div className={`${styles.contmargin}`}>
            <div className={`${styles.requestheader}`}>
              <Grid container alignItems={"center"}>
                <Grid item lg={9} md={9} sm={9} xs={9}>
                  <h3>Shelf 21 Features</h3>
                  <p>
                    See the list of features you have access to as a premium
                    user of shelf 21. Empowering Schools, Engaging Students, and
                    Connecting Parents, all in one platform.
                  </p>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={4}>
                  <div
                    className={`${styles.explore} flex align-item-center gap-8 justify-end`}
                  >
                    <Link>Sign up to explore all</Link>
                    <div>
                      <NorthEastIcon className="light-blue margin-top-10" />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div>
              <TabContext value={value}>
                <Box
                  sx={{
                    maxWidth: "787px",
                    marginTop: "54px",
                    bgcolor: "#E6F0FF",
                    borderRadius: "8px",
                    padding: "8px",
                    marginBottom: "30px",
                    color: "#0065FF"
                  }}
                >
                  <Tabs
                    onChange={handleChange}
                    TabIndicatorProps={{
                      style: { backgroundColor: "#0065FF", color: "white" }
                    }}
                    value={value}
                    centered
                    sx={{
                      "& MuiTabs": {
                        justifyContent: "space-between !important"
                      },
                      "& button": {
                        color: "#0065FF",
                        fontSize: "20px",
                        fontWeight: "700",
                        padding: "0px",
                        width: "243px"
                      },
                      "& button:hover": {
                        backgroundColor: "#0065FF",
                        color: "#FFF",
                        transition: "1s",
                        borderRadius: "8px"
                      },
                      "& button:active": {
                        backgroundColor: "#0065FF",
                        color: "#FFF",
                        transition: "1s",
                        borderRadius: "8px"
                      },
                      "& button:focus": {
                        backgroundColor: "#0065FF",
                        color: "#FFF",
                        transition: "1s",
                        borderRadius: "8px"
                      },
                      "& button:not(:last-child)": {
                        marginRight: "8px"
                      }
                    }}
                  >
                    <Tab sx={{ textTransform: "capitalize", "& .MuiTab-label": { textTransform: "lowercase" } }} label="All Features" value="1" />
                    <Tab sx={{ textTransform: "capitalize", "& .MuiTab-label": { textTransform: "lowercase" } }} label="Adminstrative Feature" value="2" />
                    <Tab sx={{ textTransform: "capitalize", "& .MuiTab-label": { textTransform: "lowercase" } }} label="Academic Features" value="3" />
                  </Tabs>
                </Box>
                <TabPanel sx={{ padding: "0px", marginBottom: "40px" }} value="1">
                  <AllFeatures
                    backgroundColor="#E6F0FF"
                    fontColor="#0065FF"
                    title="FEATURE"
                    featuretitle="Attendance and Roll-call"
                    featuredesc="Empowering Schools, Engaging Students, and Connecting Parents, all in one platform. Empowering Schools, Engaging Students, and Connecting Parents, all in one platform."
                  />
                  <AllFeatures
                    backgroundColor="#E7F5EC"
                    fontColor="#0F973D"
                    title="FEATURE"
                    featuretitle="Attendance and Roll-call"
                    featuredesc="Empowering Schools, Engaging Students, and Connecting Parents, all in one platform. Empowering Schools, Engaging Students, and Connecting Parents, all in one platform."
                  />
                  <AllFeatures
                    backgroundColor="#FEF2F2"
                    fontColor="#E77B7B"
                    title="FEATURE"
                    featuretitle="Attendance and Roll-call"
                    featuredesc="Empowering Schools, Engaging Students, and Connecting Parents, all in one platform. Empowering Schools, Engaging Students, and Connecting Parents, all in one platform."
                  />
                </TabPanel>
                <TabPanel value="2">
                </TabPanel>
                <TabPanel value="3"></TabPanel>
              </TabContext>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default index;
