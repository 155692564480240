import styles from "./Documentation.module.css";
import ButtonArr from "../../../components/Button/ButtonArr";
import ApiProcurement from "../../../components/ApiDocumentation/ApiProcurement";
import RedIcon from "../../../assets/images/featureicon.svg";
import { Grid } from "@mui/material";

const Documentation = () => {
  return (
    <div className={`${styles.bckgoundblue}`}>
      <div className={`${styles.container}`}>
        <div className={`${styles.contmargin}`}>
          <div className={`${styles.docheader}`}>
            <p className="light-blue">API Documentation</p>
            <h2 className="margin-top-16">
              Build for a world class ecosystem, Build with us.
            </h2>
            <p className="max-width-962 margin-top-16">
              Record and report on safeguarding information including background
              checks, and safer recruitment
            </p>
            <p className="max-width-962">
              trainingRecord and report on safeguarding information including
              background checks, and safer recruitment training
            </p>
            <div className="margin-top-40">
              <ButtonArr title="View API references" />
            </div>
          </div>
          <div className="margin-top-16">
            <Grid container spacing={2}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <ApiProcurement
                  image={RedIcon}
                  apiTitle="Procurements"
                  description="Integrate our powerful and stable procurement API to the system you are building for ease and accessibility."
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <ApiProcurement
                  image={RedIcon}
                  apiTitle="Procurements"
                  description="Integrate our powerful and stable procurement API to the system you are building for ease and accessibility."
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <ApiProcurement
                  image={RedIcon}
                  apiTitle="Procurements"
                  description="Integrate our powerful and stable procurement API to the system you are building for ease and accessibility."
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <ApiProcurement
                  image={RedIcon}
                  apiTitle="Procurements"
                  description="Integrate our powerful and stable procurement API to the system you are building for ease and accessibility."
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <ApiProcurement
                  image={RedIcon}
                  apiTitle="Procurements"
                  description="Integrate our powerful and stable procurement API to the system you are building for ease and accessibility."
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <ApiProcurement
                  image={RedIcon}
                  apiTitle="Procurements"
                  description="Integrate our powerful and stable procurement API to the system you are building for ease and accessibility."
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documentation;
