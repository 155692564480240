import styles from "./Button.module.css";
import PropTypes from "prop-types";

// eslint-disable-next-line react/prop-types
const Button = ({ title }) => {
  return (
    <div className={`${styles.btn}`}>
      <p>{title}</p>
    </div>
  );
};
Button.PropTypes = {
  title: PropTypes.string
};

export default Button;
